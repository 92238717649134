import React from 'react';

interface MobileNavButtonProps {
  showMobileMenu: boolean;
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MobileNavButton: React.FC<MobileNavButtonProps> = ({
  showMobileMenu,
  setShowMobileMenu,
}) => {
  return (
    <button
      className="flex sm:hidden flex-col self-center justify-between h-6 w-8 relative z-20"
      onClick={() => setShowMobileMenu((p) => !p)}
      aria-label="mobile navigation toggler"
    >
      <span
        className={`absolute w-full h-0.5 bg-pinky transition-all ${
          showMobileMenu ? 'rotate-45 top-1/2' : 'rotate-0 top-0'
        }`}
      />
      <span
        className={`absolute top-1/2 -translate-y-1/2 w-full h-0.5 bg-pinky transition-all ${
          showMobileMenu ? 'opacity-0' : 'opacity-100'
        }`}
      />
      <span
        className={`absolute bottom-0 w-full h-0.5 bg-pinky transition-all ${
          showMobileMenu ? '-rotate-45 top-1/2' : ''
        }`}
      />
    </button>
  );
};
