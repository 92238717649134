import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import { SectionWrapper } from '../../shared/components';
import PassionImg from '../../shared/images/passion.jpeg';
import DevotionImg from '../../shared/images/devotion.jpeg';
import CareImg from '../../shared/images/care.jpeg';
import { Photo } from './components';
import { useMediaQuery } from 'react-responsive';

export const PhotosComponent: React.FC = () => {
  const smallScreen = !useMediaQuery({ minWidth: '960px' });

  return (
    <section
      className="flex flex-col sm:flex-row justify-around items-center h-full sm:h-40 md:h-72 bg-pinky my-12 px-12 sm:px-0"
      data-role="photos section"
    >
      {smallScreen ? (
        <>
          <Photo
            img={PassionImg}
            caption="Pasja"
            wrapperClass="w-full sm:w-1/3 sm:px-5 -translate-y-6 sm:-translate-y-6"
          />
          <Photo
            img={DevotionImg}
            caption="Poświęcenie"
            wrapperClass="w-full sm:w-1/3 sm:px-5 sm:translate-y-6"
          />
          <Photo
            img={CareImg}
            caption="Opieka"
            wrapperClass="w-full sm:w-1/3 sm:px-5 translate-y-10 sm:-translate-y-2"
          />
        </>
      ) : (
        <>
          <Parallax
            translateY={[-20, -5]}
            translateX={[15, 0]}
            className="w-1/4 px-2 lg:w-1/5"
          >
            <Photo img={PassionImg} caption="Pasja" />
          </Parallax>

          <Parallax translateY={[25, 5]} className="w-1/4 px-2 lg:w-1/5">
            <Photo img={DevotionImg} caption="Poświęcenie" />
          </Parallax>

          <Parallax
            translateY={[-5, 5]}
            translateX={[-15, 5]}
            className="w-1/4 px-2 lg:w-1/5"
          >
            <Photo img={CareImg} caption="Opieka" />
          </Parallax>
        </>
      )}
    </section>
  );
};

export const Photos = SectionWrapper(PhotosComponent, true);
