import React from 'react';
import { SectionIdsType } from '../../../models';

interface NavigationListProps {
  showMobileMenu: boolean;
  scroll: (s: SectionIdsType) => void;
}

export const NavigationList: React.FC<NavigationListProps> = ({
  showMobileMenu,
  scroll,
}) => {
  const styles = {
    normal:
      'sm:relative sm:h-full sm:flex-row sm:justify-end sm:left-0 sm:text-lg sm:pt-0 sm:space-y-0 sm:space-x-6 md:space-x-12',
    mobile:
      'absolute w-full h-screen flex flex-col justify-start text-2xl pt-24 space-y-12',
    all: 'top-0 items-center transition-all text-pinky z-10 bg-main',
  };

  return (
    <ul
      className={`${styles.normal} ${styles.mobile} ${styles.all} ${
        showMobileMenu ? 'left-0' : 'left-full'
      }`}
    >
      <li
        className="hover:scale-105 transition-transform cursor-pointer"
        onClick={() => scroll('services')}
      >
        Usługi
      </li>
      <li
        className="hover:scale-105 transition-transform cursor-pointer"
        onClick={() => scroll('places')}
      >
        Gdzie nas znaleźć?
      </li>
      <li
        className="hover:scale-105 transition-transform cursor-pointer"
        onClick={() => scroll('contact')}
      >
        Kontakt
      </li>
    </ul>
  );
};
