import React from 'react';
import { SectionTitle, SectionWrapper } from '../../shared/components';
import { Place } from './components';

const PlacesComponent: React.FC = () => {
  return (
    <>
      <SectionTitle title="Gdzie nas znaleźć?" />
      <section className="flex flex-col items-center space-y-8" data-role="places section">
        <Place
          title="PregnaVet"
          street="Alojzego Felińskiego 17"
          town="41-908 Bytom"
          isMainOne
          url="https://www.facebook.com/PregnaVet"
        />
        <div
          className="flex flex-col lg:flex-row items-center lg:justify-around flex-wrap w-full gap-8"
          data-role="additional places"
        >
          <Place
            title="Przychodnia Weterynaryjna Alphavet"
            street="Obozowa 38B / 1"
            town="30-383 Kraków-Podgórze"
            url="https://alphavet.pl/"
          />
        </div>
      </section>
    </>
  );
};

export const Places = SectionWrapper(PlacesComponent);
