import React from 'react';
import { SectionTitle, SectionWrapper } from '../../shared/components';

const tailwindClasses = {
  ul: 'flex flex-col space-y-4 w-full md:w-1/2 md:mr-24',
};

const ServicesComponent: React.FC = () => {
  return (
    <>
      <SectionTitle title="Nasze usługi" />
      <section className="flex flex-col md:flex-row services" data-role="services section">
        <ul className={`${tailwindClasses.ul}`}>
          <li>przygotowanie do krycia</li>
          <li>
            wyznaczanie optymalnego terminu krycia lub inseminacji (USG jajników, cytologia,
            progesteron, waginoskopia)
          </li>
          <li>inseminacja dopochwowa, endoskopowa domaciczna, chirurgiczna</li>
          <li>prowadzenie ciąży, szczegółowe USG prenatalne, biometria płodów</li>
          <li>wyznaczanie terminu porodu</li>
          <li>cesarskie cięcie planowane i interwencyjne</li>
          <li>pomoc w porodach naturalnych</li>
          <li>opieka neonatologiczna nad słabymi noworodkami</li>
          <li>odrobaczanie, szczepienia i chipowanie miotów</li>
        </ul>
        <ul className={tailwindClasses.ul}>
          <li>pełne badanie USG narządu rodnego samic i samców</li>
          <li>badanie nasienia u psów i kocurów</li>
          <li>diagnostyka i leczenie przyczyn niepłodności u samic i samców</li>
          <li>przygotowanie i wysyłka nasienia chłodzonego</li>
          <li>mrożenie i przechowywanie nasienia psów</li>
          <li>zabiegi chirurgiczne</li>
          <li>
            szeroki zakres badań oferowany we współpracy z laboratorium zewnętrznym (badania
            krwi, mikrobiologiczne, genetyczne)
          </li>
          <li>wizyty domowe</li>
          <li>wystawanie paszportów</li>
        </ul>
      </section>
    </>
  );
};

export const Services = SectionWrapper(ServicesComponent, false);
