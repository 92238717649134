import React from 'react';

interface PhotoProps {
  img: string;
  caption: string;
  wrapperClass?: string;
}

export const Photo: React.FC<PhotoProps> = ({ img, caption, wrapperClass = '' }) => {
  return (
    <div className={wrapperClass}>
      <figure className="flex flex-col">
        <picture>
          <img
            src={img}
            className="w-full h-96 sm:h-64 md:h-80 lg:h-96 rounded-lg"
            style={{ objectFit: 'cover', objectPosition: 'right top' }}
            alt={caption}
            loading="lazy"
          />
        </picture>
        <figcaption className="text-center font-semibold text-xl">{caption}</figcaption>
      </figure>
    </div>
  );
};
