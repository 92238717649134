import { SectionIdsType } from '../models';

export const useScrollTo = () => {
  const handleScrollTo = (elId: SectionIdsType) => {
    const element = document.getElementById(`${elId}`);

    if (!element) return;

    window?.scroll?.({ top: element.offsetTop - 64, behavior: 'smooth' });
  };

  return { scrollTo: handleScrollTo };
};
