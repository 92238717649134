import React from 'react';

interface SectionTitleProps {
  title: string;
  isMainSection?: boolean;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  isMainSection = false,
}) => {
  return (
    <>
      {isMainSection ? (
        <h1 className="mb-2">{title}</h1>
      ) : (
        <h2 className="mb-2">{title}</h2>
      )}
      <hr className="mb-4 w-full sm:w-[264px] -ml-4 border-b-4 border-b-pinky border-double" />
    </>
  );
};
