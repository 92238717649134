import React from 'react';
import { SectionTitle, SectionWrapper } from '../../shared/components';
import AlaImg from '../../shared/images/ala.jpeg';

const PregnavetComponent: React.FC = () => {
  return (
    <section
      className="flex flex-col-reverse md:flex-row justify-between py-6 sm:py-14 md:py-24"
      data-role="pregnavet section"
    >
      <picture className="flex justify-center md:justify-start w-full md:w-1/2 mt-4 md:mt-0">
        <img src={AlaImg} className="w-3/4 sm:w-1/2 md:w-3/5 rounded-lg md:ml-12" alt="" />
      </picture>
      <div className="flex flex-col justify-center w-full md:w-1/2">
        <SectionTitle title="PregnaVet" isMainSection />
        <article className="flex flex-col space-y-3">
          <p>
            Specjalistyczne usługi weterynaryjne z zakresu rozrodu psów i kotów zapewniające
            kompleksową i najwyższej jakości opiekę nad Państwa hodowlą.
          </p>
          <p>
            PregnaVet to komfort świadomości, że Wasza hodowla jest w najlepszych rękach, a
            profesjonalna pomoc jest dostępna zawsze wtedy, gdy istnieje taka potrzeba.
          </p>
        </article>
      </div>
    </section>
  );
};

export const Pregnavet = SectionWrapper(PregnavetComponent);
