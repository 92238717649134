import React from 'react';
import { toast } from 'react-toastify';
import { SectionTitle, SectionWrapper } from '../../shared/components';
import ContactImg from '../../shared/images/contact.png';
import { ReactComponent as CopyIcon } from '../../shared/images/copy.svg';

const ContactComponent: React.FC = () => {
  const copyText = (s: string, context: string) => {
    navigator.clipboard.writeText(s);
    toast.clearWaitingQueue();
    toast.dismiss();
    setTimeout(() => toast(`Skopiowano ${context}`), 0);
  };

  return (
    <>
      <SectionTitle title="Kontakt" />
      <section className="flex justify-center" data-role="contact section">
        <div className="flex items-center shadow-xl rounded-md w-full md:w-4/5 lg:w-3/5 h-[172px] sm:h-[164px] bg-white p-4">
          <picture className="flex justify-end items-center h-full sm:h-4/5 w-0 sm:w-1/4 md:w-1/3 border-r-4 border-r-light mr-4 sm:mr-5 pr-0 sm:pr-5">
            <img src={ContactImg} className="h-5/6" alt="kontakt" />
          </picture>
          <article
            className="flex flex-col justify-evenly sm:justify-between h-full sm:h-5/6"
            data-role="contact information"
          >
            <h3>lek. wet. Alicja Paździerkiewicz</h3>
            <section className="flex flex-col space-y-1">
              <span className="flex items-center space-x-1 h-6">
                <a className="mb-0.5" href="mailto:pregnavet@gmail.com">
                  pregnavet@gmail.com
                </a>
                <CopyIcon
                  className="transition-all scale-50 hover:skew-y-3 hover:skew-x-3 fill-main hover:fill-secondary cursor-pointer"
                  onClick={() => copyText('pregnavet@gmail.com', 'e-mail')}
                />
              </span>
              <span className="flex items-center space-x-1 h-6">
                <a href="tel:+48662038616" className="mb-0.5">
                  +48 662 038 616
                </a>
                <CopyIcon
                  className="transition-all scale-50 hover:skew-y-3 hover:skew-x-3 fill-main hover:fill-secondary cursor-pointer"
                  onClick={() => copyText('+48662038616', 'numer telefonu')}
                />
              </span>
            </section>
          </article>
        </div>
      </section>
    </>
  );
};

export const Contact = SectionWrapper(ContactComponent);
