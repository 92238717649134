import React from 'react';
import { SectionIdsType } from '../../models';

interface SectionWrapperProps {
  title: SectionIdsType;
}

export const SectionWrapper =
  (Component: React.FC, omitHorizontalPadding?: boolean) =>
  ({ title }: SectionWrapperProps) => {
    const mainComponent = (
      <main
        className={`${
          omitHorizontalPadding ? '' : 'px-12 sm:px-20 md:px-24 lg:px-44 xl:px-72'
        } py-8`}
        role="main"
        data-role={`${title} main`}
        id={title}
      >
        <Component />
      </main>
    );

    return mainComponent;
  };
