import React from 'react';
import { ReactComponent as PawIcon } from '../../../shared/images/paw.svg';

interface PlaceProps {
  title: string;
  street: string;
  town: string;
  isMainOne?: boolean;
  url: string;
}

export const Place: React.FC<PlaceProps> = ({
  title,
  street,
  town,
  isMainOne = false,
  url,
}) => {
  return (
    <a
      className={`group flex flex-row w-full md:w-3/4 ${
        isMainOne ? 'lg:w-96' : 'lg:w-80 xl:w-96'
      } bg-gradient-to-br from-white via-white to-pinky shadow-xl rounded-md p-3`}
      data-role={`${title} section`}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex flex-col items-start w-full mr-2">
        <h4
          className={`${
            isMainOne ? 'text-xl' : ''
          } font-black transition-all group-hover:ml-0.5`}
        >
          {title ?? ''}
        </h4>
        <hr className="my-2 w-[44px] -ml-6 border-b-2 border-b-secondary transition-all group-hover:-ml-8" />
        <article className="flex flex-col justify-center h-full">
          <p>ul. {street}</p>
          <p>{town}</p>
        </article>
      </div>

      <PawIcon className="stroke-main fill-light w-12 h-12 transition-colors group-hover:fill-secondary" />
    </a>
  );
};
