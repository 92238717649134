import React from 'react';

export const Footer: React.FC = () => {
  return (
    <footer
      className="flex flex-row justify-center w-full p-4 bg-secondary text-light"
      data-role="footer"
    >
      <span>PregnaVet ® 2024</span>
    </footer>
  );
};
