import React, { useState } from 'react';
import { useScrollTo } from '../../hooks';
import { SectionIdsType } from '../../models';
import { MobileNavButton, NavigationList } from './components';
import MainLogo from '../../images/mainlogo.png';

export const Navbar: React.FC = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { scrollTo } = useScrollTo();

  const scroll = (s: SectionIdsType) => {
    scrollTo(s);
    setShowMobileMenu(false);
  };

  return (
    <nav
      className="fixed top-0 flex flex-row justify-end items-center w-full h-16 pr-4 sm:pr-6 md:pr-10 bg-main z-10"
      aria-label="main navigation"
    >
      <picture
        className="mr-auto bg-pinky h-16 w-3/5 sm:w-1/3 min-w-[112px] pl-2 md:pl-4 py-1 bg-gradient-to-r from-pinky to-main cursor-pointer"
        onClick={() => scroll('pregnavet')}
      >
        <img src={MainLogo} className="h-full" alt="pregnavet logo" />
      </picture>

      <MobileNavButton
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
      />

      <NavigationList showMobileMenu={showMobileMenu} scroll={scroll} />
    </nav>
  );
};
