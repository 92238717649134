import { Pregnavet } from './features/pregnavet';
import { Contact } from './features/contact';
import { Services } from './features/services';
import { Navbar, Footer } from './shared/components';
import { Places } from './features/places';
import { Photos } from './features/photos';

export const App = () => {
  return (
    <div
      className="flex flex-col h-full w-full overflow-hidden relative"
      data-role="main wrapper"
    >
      <Navbar />
      <main role="main" className="flex flex-col pt-16" data-role="main content wrapper">
        <Pregnavet title="pregnavet" />
        <Photos title="photos" />
        <Services title="services" />
        {/* <News title="news" /> */}
        <Places title="places" />
        <Contact title="contact" />
      </main>
      <Footer />
    </div>
  );
};
